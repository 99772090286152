<template lang="html">
    <div class="container main">
        <top-navigation-bar
            title="Binomial"
            right-nav="reset"
            @click="doReset"
        />
        <div class="inline-input-view d-flex">
            <h3 class="mx-2">
                n
            </h3>
            <input
                ref="n"
                v-model="n"
                v-b-tooltip.hover.bottom="getTip('n')"
                class="input-single inline"
                placeholder="?"
                @keyup.enter="onSubmitNP()"
            >
            <h3 class="mx-2 ml-4">
                p
            </h3>
            <input
                ref="p"
                v-model="p"
                v-b-tooltip.hover.bottom="getTip('p')"
                class="input-single inline"
                placeholder="?"
                @keyup.enter="onSubmitNP()"
            >
            <button
                class="action-btn m-auto"
                @click="showModal()"
            >
                Formula
            </button>
        </div>

        <div class="m-view d-flex">
            <h3>P(x </h3>
            <select
                v-model="selected.value"
                v-b-tooltip.hover.bottom="getTip('picker')"
                class="form-select mx-2"
                @change="onSubmitSingle()"
            >
                <option
                    v-for="(option, key) in options"
                    :key="key"
                >
                    {{ option.value }}
                </option>
            </select>
            <input
                ref="x"
                v-model="x"
                v-b-tooltip.hover.bottom="getTip('x')"
                class="eq-inline-input"
                placeholder="?"
                @input="onChangeSingle()"
                @keyup.enter="onSubmitSingle()"
                @focus="onFocus()"
            >
            <h3>)= {{ prob }}</h3>
        </div>

        <h4 class="mt-2">
            — OR —
        </h4>

        <div class="m-view d-flex">
            <h3>P( </h3>
            <input
                ref="bLow"
                v-model="bLow"
                v-b-tooltip.hover.bottom="getTip('bLow')"
                class="eq-inline-input"
                placeholder="?"
                @input="onChangeBetween()"
                @keyup.enter="onSubmitBetween()"
                @focus="onFocus()"
            >
            <select
                v-model="selectedBetween.value"
                v-b-tooltip.hover.bottom="getTip('picker')"
                class="form-select mx-1"
                @change="onSubmitBetween()"
            >
                <option
                    v-for="(option, key) in optionsBetween"
                    :key="key"
                >
                    {{ option.value }}
                </option>
            </select>
            <input
                v-model="aHigh"
                v-b-tooltip.hover.bottom="getTip('aHigh')"
                class="eq-inline-input"
                placeholder="?"
                @input="onChangeBetween()"
                @keyup.enter="onSubmitBetween()"
                @focus="onFocus()"
            >
            <h3>)= {{ probC }}</h3>
        </div>

        <highcharts
            class="py-3 mb-5"
            :options="zChart.chartOptions"
        />

        <modal
            ref="modalComponent"
            title="Formula"
            style-class="modal-bottom"
            modal-type="general"
        >
            <template #body>
                <img
                    class="formulaImage"
                    alt="binomial formula"
                    src="@/assets/helpImgs/BinomialDistribution.jpg"
                >
            </template>
        </modal>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import Modal from '@/components/modal.vue'
import binomialData from '@/js/distributions/binomial-data.js'
import { isEmptyNumsCustom, clearData } from '@/js/lib/input-check.js'
import { tips } from '@/js/lib/tooltip-text.js'
import errorText from '@/js/lib/error-handle-text.js'
import { Chart } from 'highcharts-vue'
import _ from 'lodash'

export default {
    name: 'Binomial',
    components: {
        TopNavigationBar,
        highcharts: Chart,
        Modal
    },
    data () {
        return {
            n: '', // Number of Trials
            p: '', // Probability of Success
            x: '', // Number of Successes
            bLow: '', // Lowerbound
            aHigh: '', // Upperbound
            prob: '', // Probability
            probC: '', // Probability Between
            graphData: [],
            zoneData: [],
            options: [
                { value: '=' },
                { value: '<' },
                { value: '≤' },
                { value: '>' },
                { value: '≥' },
            ],
            selected: { value: '≤' },
            optionsBetween: [
                { value: '< x <' },
                { value: '≤ x ≤' },
                { value: '≤ x <' },
                { value: '< x ≤' },
            ],
            selectedBetween: { value: '≤ x ≤' },
        }
    },
    computed: {
        zChart () {
            return {
                chartOptions: {
                    chart: {
                        type: 'column',
                        height: 400,
                    },
                    xAxis: {
                        title: {
                            text: '# of Successes'
                        },
                        min: 0,
                    },
                    yAxis: {
                        title: {
                            text: 'Probability',
                        },
                    },
                    legend: {
                        enabled: false,
                    },
                    tooltip: {
                        enabled: true,
                    },
                    series: [{
                        data: _.get(this.graphData, 'series', []),
                        name: 'Probability',
                    },
                    ],
                    plotOptions: {
                        series: {
                            pointPadding: 0,
                            groupPadding: 0
                        },
                        column: {
                            zoneAxis: 'x',
                            zones: [
                                {
                                    value: _.get(this.zoneData.zones, 'low', []),
                                    color: 'rgba(198, 57, 57, 0.35)'
                                },
                                {
                                    value: _.get(this.zoneData.zones, 'high', []),
                                    color: 'red'
                                }, {
                                    color: 'rgba(198, 57, 57, 0.35)'
                                }]
                        }
                    }

                }
            }
        },
    },
    methods: {
        getTip (key) {
            var tipsData = _.merge(tips.general, tips.binomial)
            return _.get(tipsData, key)
        },
        doReset () {
            _.assign(this, clearData(this, ['n', 'p', 'x', 'bLow', 'aHigh', 'prob', 'probC', 'zoneData', 'graphData']))
        },
        showModal () {
            this.$refs.modalComponent.show()
        },
        onChangeSingle () {
            _.assign(this, clearData(this, ['prob', 'bLow', 'aHigh', 'probC', 'zoneData']))
        },
        onChangeBetween () {
            _.assign(this, clearData(this, ['x', 'prob', 'probC', 'zoneData']))
        },
        onSubmitSingle () {
            var { n, p } = this
            if (!isEmptyNumsCustom(this.x)) {
                if (binomialData.checkNP(n, p)) { return }
                this.onSubmitNP()
                this.onChangeSingle()
                const output = binomialData.calculateSingle(this)
                _.set(this, 'zoneData', output)
                _.assign(this, { prob: output.sum })
            }
        },
        onSubmitBetween () {
            var { n, p } = this
            if (!isEmptyNumsCustom(this.bLow, this.aHigh)) {
                if (binomialData.checkNP(n, p)) { return }
                this.onSubmitNP()
                this.onChangeBetween()
                var output = binomialData.calculateBetween(this)
                _.set(this, 'zoneData', output)
                _.assign(this, { probC: output.sum })
            }
        },
        onSubmitNP () {
            var { n, p } = this
            if (binomialData.checkNP(n, p)) { return }
            const series = binomialData.loadGraphData(this)
            _.set(this, 'graphData', { series })
        },
        onFocus () {
            var { n, p } = this
            if (isEmptyNumsCustom(n, p)) {
                alert(errorText.distributionAlert.firstInputBinomial)
                this.$refs.n.focus()
                return
            }
            if (binomialData.checkN(n)) {
                this.$refs.n.focus()
                return
            }
            if (binomialData.checkP(p)) {
                this.$refs.p.focus()
            }
        }
    },
}
</script>
