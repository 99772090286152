import { isEmptyNums, isInvalidNums, isNotPercentage, isValidTrials, isNegativeCustomAB, isInvalidNumsCustom, isEmptyNumsCustom, isNotWholeNumberCustom, dataToNums } from '@/js/lib/input-check.js'
import errorText from '@/js/lib/error-handle-text.js'
import _ from 'lodash'
var pmf = require('binomial-pmf')
var cdf = require('binomial-cdf')

const calculateSingle = (data) => {
    var { n, p, x, selected } = data
    selected = selected.value
    var zones
    var sum = 0
    var logicError = errorText.binomialAlert
    var numberx = Number(x)
    var parsedn = parseFloat(n)
    var parsedp = parseFloat(p)

    if ((isInvalidNumsCustom(numberx)) || (isEmptyNumsCustom(x)) || (isNotWholeNumberCustom(numberx)) || (numberx > parsedn) || (numberx < 0)) {
        alert(logicError.singleInput)
        return
    }

    if (selected === '=') { // remove x checks
        sum = pmf(numberx, parsedn, parsedp)
        zones = { low: numberx, high: numberx + 1 }
    }
    if (selected === '<') {
        const xLess = numberx - 1
        sum = cdf(xLess, parsedn, parsedp)
        zones = { low: 0, high: numberx }
    }
    if (selected === '≤') {
        sum = cdf(numberx, parsedn, parsedp)
        zones = { low: 0, high: numberx + 1 }
    }
    if (selected === '>') {
        sum = 1.0 - cdf(numberx, parsedn, parsedp)
        zones = { low: numberx + 1, high: parsedn + 1 }
    }
    if (selected === '≥') {
        const xGreater = numberx - 1
        sum = 1.0 - cdf(xGreater, parsedn, parsedp)
        zones = { low: numberx, high: parsedn + 1 }
    }
    return { sum: Number(sum.toFixed(4)), zones }
}

const calculateBetween = (data) => {
    var { n, p, bLow, aHigh, graphData, selectedBetween } = data

    graphData = graphData.series
    selectedBetween = selectedBetween.value
    var zones
    var sum = 0
    var logicError = errorText.binomialAlert
    var numbern = Number(n)
    var numberB = Number(bLow)
    var numberA = Number(aHigh)

    if (checkNP(n, p)) { return }
    if (isEmptyNums(aHigh, bLow)) { return }
    if (isInvalidNums(numberA, numberB)) { return }

    if ((isNotWholeNumberCustom(numberA, numberB)) || (isNegativeCustomAB(numberA, numberB)) || (numberB > numbern || numberA > numbern)) {
        alert(logicError.betweenInput)
        return
    }
    if ((aHigh === '' && bLow !== '') || (aHigh !== '' && bLow === '')) {
        alert(errorText.distributionAlert.missingBetweenAlert)
        return
    }

    if (selectedBetween === '< x <') { // < <
        if (numberB > numberA) {
            alert(logicError.lessThan)
            return
        }
        zones = { low: numberB + 1, high: numberA }
        sum = getValueSum(graphData, numberB + 1, numberA - 1)
    }
    if (selectedBetween === '≤ x ≤') { // ≤ ≤
        if (numberB > numberA) {
            alert(logicError.lessOrEqual)
            return
        }
        zones = { low: numberB, high: numberA + 1 }
        sum = getValueSum(graphData, numberB, numberA)
    }
    if (selectedBetween === '≤ x <') { // ≤ <
        if (numberB > numberA) {
            alert(logicError.lessThan)
            return
        }
        zones = { low: numberB, high: numberA }
        sum = getValueSum(graphData, numberB, numberA - 1)
    }
    if (selectedBetween === '< x ≤') { // < ≤
        if (numberB > numberA) {
            alert(logicError.lessOrEqual)
            return
        }
        zones = { low: numberB + 1, high: numberA + 1 }
        sum = getValueSum(graphData, numberB + 1, numberA)
    }
    return { sum: Number(sum.toFixed(4)), zones }
}

const getObjVals = (data, key) => {
    return data.map(v => { return v[key] })
}

const getValueSum = (data, b, a) => {
    let result = 0
    var vals = getObjVals(data, 'y')
    vals.slice(b, (a + 1)).forEach(v => {
        result += v
    })
    return result
}

const loadGraphData = (data) => {
    _.assign(data, dataToNums(data, ['n', 'p']))
    var { n, p } = data
    var output = _.map(_.range(n + 1), (v, k) => {
        return {
            x: k,
            y: Number(pmf(v, n, p).toFixed(4)), // (=) Calculation
            name: `Successes: ${k}`,
        }
    })
    return output
}

const checkNP = (n, p) => {
    if (checkN(n) || checkP(p)) {
        return true
    }
}

const checkN = (n) => {
    var numbern = _.toNumber(n)
    if (
        isEmptyNums(n) ||
        isInvalidNums(numbern) ||
        isValidTrials(numbern)
    ) {
        return true
    }
}

const checkP = (p) => {
    var numberp = _.toNumber(p)
    if (
        isEmptyNums(p) ||
        isInvalidNums(numberp) ||
        isNotPercentage(numberp)
    ) {
        return true
    }
}

export default {
    calculateSingle,
    calculateBetween,
    loadGraphData,
    checkNP,
    checkN,
    checkP
}
